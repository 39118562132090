import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import ModalComponent from 'app/shared/components/modal/modal.component';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import CorpusService from 'app/utils/services/corpus.service';
import { CorpusService as CorpusApi } from 'app/api/services';

export enum Display {
    LIST = 'list-display', // match le nom de l'icone à utiliser dans le svg
    GRID = 'display-grid', // match le nom de l'icone à utiiser dans le svg
}

@Injectable({
    providedIn: 'root',
})
export default class HomeService {
    selectedDisplayMode: Display = Display.GRID;

    constructor(
        private modalService: NgbModal,
        private translateService: TranslateService,
        private corpusApi: CorpusApi,
        private corpusService: CorpusService,
    ) { }

    deleteCorpus(row, track_category = 'page projets') {
        const modal = this.modalService.open(ModalComponent, {});
        modal.componentInstance.titleToTranslate = 'translations.home.delete_project_title';
        modal.componentInstance.content = this.translateService.instant('translations.home.delete_project_message', { name: row.corp_name });
        modal.componentInstance.btnCloseToTranslate = 'translations.utils.cancel';
        modal.componentInstance.btnValidToTranslate = 'translations.utils.confirm';
        modal.componentInstance.btnValidVariant = 'primary';
        modal.componentInstance.status.subscribe(async (s: boolean) => {
            if (s) {
                gtmClick({
                    track_category,
                    track_name: 'supprimer projet',
                    track_cible: 'valider',
                });
                this.corpusApi
                    .deleteV1CorpusCorpId(row.corp_id)
                    .subscribe({
                        next: () => {
                            this.corpusService.loadCorpusListFromBackend();
                        },

                        error: (e) => {
                            const m = this.modalService.open(ModalComponent, {});
                            m.componentInstance.titleToTranslate = 'translations.httpErrors.title';
                            m.componentInstance.contentToTranslate = `translations.httpErrors.${e?.error?.error || 'title'}`;
                            m.componentInstance.alertTypeVariant = 'danger';
                        },
                    });
            } else {
                gtmClick({
                    track_category,
                    track_name: 'supprimer projet',
                    track_cible: 'annuler',
                });
            }
        });
    }
}


