import {
    Component, TemplateRef, ViewChild, AfterViewInit,
    Input,
    OnInit,
} from '@angular/core';
import CorpusService from 'app/utils/services/corpus.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'app/base.component';
import HomeService from '../home.service';

@Component({
    selector: 'project-list',
    templateUrl: './project-list.component.html',
    styleUrl: './project-list.component.scss',
})
export default class ProjectListComponent extends BaseComponent implements AfterViewInit, OnInit {
    @ViewChild('favRowTpl')
        favRowTpl: TemplateRef<any>;

    @ViewChild('favColTpl')
        favColTpl: TemplateRef<any>;

    @ViewChild('colTpl')
        colTpl: TemplateRef<any>;

    @ViewChild('nameRowTpl')
        nameRowTpl: TemplateRef<any>;

    @ViewChild('toolsRowTpl')
        toolsRowTpl: TemplateRef<any>;

    @ViewChild('toolsColTpl')
        toolsColTpl: TemplateRef<any>;

    @ViewChild('dateRowTpl')
        dateRowTpl: TemplateRef<any>;

    @ViewChild('languageRowTpl')
        languageRowTpl: TemplateRef<any>;

    @ViewChild('userRowTpl')
        userRowTpl: TemplateRef<any>;

    @ViewChild('roleRowTpl')
        roleRowTpl: TemplateRef<any>;

    @ViewChild('actionsRowTpl')
        actionsRowTpl: TemplateRef<any>;

    public columns: Array<any> = [];

    public config: any = {
        style: {
            overflow: 'hidden',
        },
        pagination: false,
    };

    showMore : boolean = false;

    lang: string = 'fr';

    allRows = [];

    displayedRows = [];

    @Input()
        type;

    constructor(
        private corpusService: CorpusService,
        private permissionsService: NgxPermissionsService,
        private homeService: HomeService,
        private translate: TranslateService,
    ) {
        super();
    }


    ngOnInit(): void {
        this.lang = (this.translate.currentLang || this.translate.defaultLang).toLowerCase();
        this.subs.sink = this.translate.onLangChange.subscribe(() => {
            this.lang = this.translate.currentLang;
        });
    }

    ngAfterViewInit() {
        const colFav = {
            prop: 'is_favori',
            cellTemplate: this.favRowTpl,
            headerTemplate: this.favColTpl,
            title: 'F',
            style: {
                'max-width': '50px',
                width: '50px',
            },
        };

        const colName = {
            prop: 'corp_name',
            title: 'translations.home.name',
            cellTemplate: this.nameRowTpl,
            headerTemplate: this.colTpl,
            style: {
                'max-width': '300px',
                width: '300px',
            },
        };

        const colKibana = {
            title: 'translations.analysisDashboard.kibana',
            cellTemplate: this.toolsRowTpl,
            headerTemplate: this.toolsColTpl,
            style: {
                'max-width': '160px',
                width: '160px',
            },
        };

        const colDate = {
            prop: 'dateLastUpload',
            title: 'translations.home.updated',
            headerTemplate: this.colTpl,
            cellTemplate: this.dateRowTpl,
            style: {
                'max-width': '160px',
                width: '160px',
            },
        };

        const colUser = {
            title: 'translations.home.author',
            cellTemplate: this.userRowTpl,
            style: {
                'max-width': '170px',
                width: '170px',
            },
        };

        const colLang = {
            prop: 'corp_lang',
            title: 'translations.home.language',
            headerTemplate: this.colTpl,
            cellTemplate: this.languageRowTpl,
            style: {
                'max-width': '100px',
                width: '100px',
            },
        };

        const colRole = {
            prop: 'role_id',
            title: 'translations.home.role',
            cellTemplate: this.roleRowTpl,
            style: {
                'max-width': '110px',
                width: '110px',
            },
        };

        const colAction = {
            title: 'translations.home.actions',
            cellTemplate: this.actionsRowTpl,
            style: {
                'max-width': '60px',
                width: '90px',
            },
        };

        this.columns = [
            colFav,
            colName,
            colKibana,
            colDate,
            this.permissionsService.getPermission('administrators') ? colUser : colRole,
            colLang,
            colAction,
        ];
    }

    @Input()
    set rows(rows) {
        this.allRows = [...rows];

        if (this.showMore) {
            this.setShowMore();
        } else {
            this.setShowLess();
        }
    }

    setShowMore() {
        this.showMore = true;
        this.displayedRows = this.allRows || [];
    }

    setShowLess() {
        this.showMore = false;
        this.displayedRows = this.allRows?.slice(0, 6) || [];
    }

    fav(id) {
        this.corpusService.fav(id);
    }

    unFav(id) {
        this.corpusService.unFav(id);
    }

    // eslint-disable-next-line class-methods-use-this
    canDeleteCorpus(row) {
        return row.role_id === 1 && !row.corp_is_public;
    }

    shareDisabled(row) {
        return row.corp_status?.error || row.corp_status?.status_flags?.isAnalyseInProgress || row.corp_status?.status_flags?.hasAsyncTreatmentInProgress || !this.canDeleteCorpus(row);
    }

    deleteCorpus(row) {
        this.homeService.deleteCorpus(row, 'page projets liste');
    }

    // eslint-disable-next-line class-methods-use-this
    getDeleteTooltipTitle(row) {
        let title = 'translations.home.delete_tooltip';

        if (row.role_id !== 1) {
            title += '_guest';
        } else if (row.corp_is_public) {
            title += '_client';
        }

        if (row.corp_type === 4) {
            title += '_conversation';
        } else {
            title += '_project';
        }

        return title;
    }
}
