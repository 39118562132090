// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.mosaic-item-container {
  display: inline-flex;
  min-height: 300px;
  width: 100%;
}

.corp-name {
  font-weight: bold;
  margin-top: 10px;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  /* 108.333% */
  letter-spacing: -0.5px;
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  /* Nombre de lignes à afficher */
  overflow: hidden;
}
.corp-name:hover {
  text-decoration: underline;
}

.top-right-icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.mosaic-item {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 2px #ccc solid;
  width: 100%;
  min-height: 350px;
  padding: 20px;
  position: relative;
}

.name-and-date {
  height: 100px;
}

.icon-kibana {
  height: 20px;
  width: 20px;
}

.text-grey {
  color: #666;
  font-weight: bold;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-icon {
  height: 24px;
  width: 24px;
  min-width: 24px;
}

.progress_spinner, .reload {
  width: 16px;
  height: 16px;
}

.reload {
  color: #4170D8;
}

.progress_spinner .path {
  animation: dash 1.5s ease-in-out infinite;
  stroke: rgb(255, 102, 0);
}

.external-link {
  font-size: 14px;
}

.external-urls {
  min-height: 25px;
}
.external-urls .solaris-icon {
  min-width: 20px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
