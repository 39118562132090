/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import {
    HttpClient, HttpHeaders, HttpRequest, HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable as __Observable } from 'rxjs';
import { filter as __filter, map as __map } from 'rxjs/operators';
import download from 'app/utils/functions/download';
import ApiConfiguration from '../api-configuration';
import BaseService from '../base-service';
import { ClassCount } from '../models/class-_count';
import { ClusterCount } from '../models/cluster-_count';
import { ColumnPaginationparams } from '../models/columnPaginationparams';
import { ColumnSearchparams } from '../models/columnSearchparams';
import { Columnsused } from '../models/columnsused';
import { Corpuslist } from '../models/corpuslist';
import { Corpusstatus } from '../models/corpusstatus';
import { CreateCorpus } from '../models/create-corpus';
import { GetCorpus } from '../models/get-corpus';
import { Log } from '../models/log';
import { ResultId } from '../models/result-id';
import { SearchResults } from '../models/search_results';
import { SimilarityBodyParams, SimilarityResponse } from '../models/similarity';
import { UpdateCorpus } from '../models/update-corpus';
import { Username } from '../models/username';
import { Userrole } from '../models/userrole';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { DashboardType } from '../models/dashboardforuser';
import { Folder, FolderType } from '../models/folder';
import { IaConfigResponse } from '../models/iaconfig';
import {
    ConversationAddTags, ConversationDeleteTag, ConversationRenameTag, VerbatimConversation, VerbatimConversationConfig, VerbatimConversationSpeakers,
    VerbatimTags,
} from '../models/verbatim-conversation';

/**
   * Parameters for patchV1CorpusCorpId
   */
export interface PatchV1CorpusCorpIdParams {

    /**
 * corpus id
 */
    corpId: number;
    body: UpdateCorpus;
}

/**
* Parameters for putV1CorpusCorpIdRole
*/
export interface PutV1CorpusCorpIdRoleParams {
    username: Userrole;

    /**
 * corpus id
 */
    corpId: number;
}

/**
* Parameters for postV1CorpusCorpIdRole
*/
export interface PostV1CorpusCorpIdRoleParams {
    username: Userrole;

    /**
 * corpus id
 */
    corpId: number;
}

/**
* Parameters for postV1CorpusCorpIdColumnsPagination
*/
export interface PostV1CorpusCorpIdColumnsPaginationParams {

    /**
 * corpus id
 */
    corpId: number;
    verbatimDbName: string;
    body?: ColumnPaginationparams;
}

/**
* Parameters for postV1CorpusCorpIdColumnsSearch
*/
export interface PostV1CorpusCorpIdColumnsSearchParams {

    /**
 * corpus id
 */
    corpId: number;
    verbatimDbName: string;
    body?: ColumnSearchparams;
}

/**
* Parameters for deleteV1CorpusCorpIdRole
*/
export interface DeleteV1CorpusCorpIdRoleParams {

    /**
 * Username
 */
    username: Username;

    /**
 * corpus id
 */
    corpId: number;
}

/**
* Parameters for GetV1CorpusCorpIdFormat
*/
export interface GetV1CorpusCorpIdFormatParams {

    /**
 * corpus id (integer)
 */
    corpId :number;

    /**
 * field name (string)
 */
    fieldname: string;
}

/**
* Parameters for PostV1CorpusCorpIdVerbatimSimilarityParams
*/
export interface PostV1CorpusCorpIdVerbatimSimilarityParams {
    corpId :number;
    verbatimDbName: string;
    body?: SimilarityBodyParams;
}
/**
 * Corpus management
 */
@Injectable({
    providedIn: 'root',
})
class CorpusService extends BaseService {
    constructor(
        config: ApiConfiguration,
        http: HttpClient,
    ) {
        super(config, http);
    }

    /**
   * Create a corpus based on a data file already uploaded on VisualCRM server
   * @param body undefined
   * @return OK
   */
    postV1CorpusResponse(body: CreateCorpus): __Observable<__StrictHttpResponse<ResultId>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;
        __body = body;
        const req = new HttpRequest<any>(
            'POST',
            `${this.rootUrl}/v1/corpus/`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<ResultId>),
        );
    }

    /**
   * Create a corpus based on a data file already uploaded on VisualCRM server
   * @param body undefined
   * @return OK
   */
    postV1Corpus(body: CreateCorpus): __Observable<ResultId> {
        return this.postV1CorpusResponse(body).pipe(
            // eslint-disable-next-line @typescript-eslint/no-shadow
            __map(({ body }) => body as ResultId),
        );
    }

    /**
   * Get the definition of the corpus
   * @param corpId corpus id
   * @return OK
   */
    getV1CorpusCorpIdResponse(corpId: number): __Observable<__StrictHttpResponse<GetCorpus>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/corpus/${corpId}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<GetCorpus>),
        );
    }

    /**
   * Get the definition of the corpus
   * @param corpId corpus id
   * @return OK
   */
    getV1CorpusCorpId(corpId: number): __Observable<GetCorpus> {
        return this.getV1CorpusCorpIdResponse(corpId).pipe(
            __map(({ body }) => body as GetCorpus),
        );
    }

    /**
   * Delete a corpus with id corpId if the current user is owner
   * @param corpId corpus id
   */
    deleteV1CorpusCorpIdResponse(corpId: number): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'DELETE',
            `${this.rootUrl}/v1/corpus/${corpId}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Delete a corpus with id corpId if the current user is owner
   * @param corpId corpus id
   */
    deleteV1CorpusCorpId(corpId: number): __Observable<null> {
        return this.deleteV1CorpusCorpIdResponse(corpId).pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * Update the corpus parameters and add new data file already uploaded on VisualCRM server
   * @param params The `PatchV1CorpusCorpIdParams` containing the following parameters:
   *
   * - `corpId`: corpus id
   *
   * - `body`:
   */
    patchV1CorpusCorpIdResponse(params: PatchV1CorpusCorpIdParams): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.body;
        const req = new HttpRequest<any>(
            'PATCH',
            `${this.rootUrl}/v1/corpus/${params.corpId}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Update the corpus parameters and add new data file already uploaded on VisualCRM server
   * @param params The `PatchV1CorpusCorpIdParams` containing the following parameters:
   *
   * - `corpId`: corpus id
   *
   * - `body`:
   */
    patchV1CorpusCorpId(params: PatchV1CorpusCorpIdParams): __Observable<null> {
        return this.patchV1CorpusCorpIdResponse(params).pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * Get the list of corpus (private and public) for the current user
   * @return OK
   */
    getV1CorpusResponse(): __Observable<__StrictHttpResponse<Corpuslist>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;
        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/corpus`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Corpuslist>),
        );
    }

    /**
   * Get the list of corpus (private and public) for the current user
   * @return OK
   */
    getV1Corpus(): __Observable<Corpuslist> {
        return this.getV1CorpusResponse().pipe(
            __map(({ body }) => body as Corpuslist),
        );
    }

    /**
   * Get the list of action done on the corpus
   * @param corpId corpus id
   * @return OK
   */
    getV1CorpusCorpIdHistoryResponse(corpId: number): __Observable<__StrictHttpResponse<Log>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/corpus/${corpId}/history`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Log>),
        );
    }

    /**
   * Get the list of action done on the corpus
   * @param corpId corpus id
   * @return OK
   */
    getV1CorpusCorpIdHistory(corpId: number): __Observable<Log> {
        return this.getV1CorpusCorpIdHistoryResponse(corpId).pipe(
            __map(({ body }) => body as Log),
        );
    }

    /**
   * Get the status of the corpus, including treatment time
   * @param corpId corpus id
   * @return OK
   */
    getV1CorpusCorpIdStatusResponse(corpId: number): __Observable<__StrictHttpResponse<Corpusstatus>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/corpus/${corpId}/status`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Corpusstatus>),
        );
    }

    /**
   * Get the status of the corpus, including treatment time
   * @param corpId corpus id
   * @return OK
   */
    getV1CorpusCorpIdStatus(corpId: number): __Observable<Corpusstatus> {
        return this.getV1CorpusCorpIdStatusResponse(corpId).pipe(
            __map(({ body }) => body as Corpusstatus),
        );
    }

    /**
   * Add this corpus as a favorite for the curent user
   * @param corpId corpus id
   */
    postV1CorpusCorpIdFavoriteResponse(corpId: number): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'POST',
            `${this.rootUrl}/v1/corpus/${corpId}/favorite`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Add this corpus as a favorite for the curent user
   * @param corpId corpus id
   */
    postV1CorpusCorpIdFavorite(corpId: number): __Observable<null> {
        return this.postV1CorpusCorpIdFavoriteResponse(corpId).pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * Delete a favorite corpus
   * @param corpId corpus id
   */
    deleteV1CorpusCorpIdFavoriteResponse(corpId: number): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'DELETE',
            `${this.rootUrl}/v1/corpus/${corpId}/favorite`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Delete a favorite corpus
   * @param corpId corpus id
   */
    deleteV1CorpusCorpIdFavorite(corpId: number): __Observable<null> {
        return this.deleteV1CorpusCorpIdFavoriteResponse(corpId).pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * Update a role to an user
   * @param params The `PutV1CorpusCorpIdRoleParams` containing the following parameters:
   *
   * - `username`:
   *
   * - `corpId`: corpus id
   */
    putV1CorpusCorpIdRoleResponse(params: PutV1CorpusCorpIdRoleParams): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;
        __body = params.username;

        const req = new HttpRequest<any>(
            'PUT',
            `${this.rootUrl}/v1/corpus/${params.corpId}/role`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Update a role to an user
   * @param params The `PutV1CorpusCorpIdRoleParams` containing the following parameters:
   *
   * - `username`:
   *
   * - `corpId`: corpus id
   */
    putV1CorpusCorpIdRole(params: PutV1CorpusCorpIdRoleParams): __Observable<null> {
        return this.putV1CorpusCorpIdRoleResponse(params).pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * Add a role to an user
   * @param params The `PostV1CorpusCorpIdRoleParams` containing the following parameters:
   *
   * - `username`:
   *
   * - `corpId`: corpus id
   */
    postV1CorpusCorpIdRoleResponse(params: PostV1CorpusCorpIdRoleParams): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;
        __body = params.username;

        const req = new HttpRequest<any>(
            'POST',
            `${this.rootUrl}/v1/corpus/${params.corpId}/role`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Add a role to an user
   * @param params The `PostV1CorpusCorpIdRoleParams` containing the following parameters:
   *
   * - `username`:
   *
   * - `corpId`: corpus id
   */
    postV1CorpusCorpIdRole(params: PostV1CorpusCorpIdRoleParams): __Observable<null> {
        return this.postV1CorpusCorpIdRoleResponse(params).pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * Delete the role about an user
   * @param params The `DeleteV1CorpusCorpIdRoleParams` containing the following parameters:
   *
   * - `username`: Username
   *
   * - `corpId`: corpus id
   */
    deleteV1CorpusCorpIdRoleResponse(params: DeleteV1CorpusCorpIdRoleParams): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;
        __body = params.username;

        const req = new HttpRequest<any>(
            'DELETE',
            `${this.rootUrl}/v1/corpus/${params.corpId}/role`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Delete the role about an user
   * @param params The `DeleteV1CorpusCorpIdRoleParams` containing the following parameters:
   *
   * - `username`: Username
   *
   * - `corpId`: corpus id
   */
    deleteV1CorpusCorpIdRole(params: DeleteV1CorpusCorpIdRoleParams): __Observable<null> {
        return this.deleteV1CorpusCorpIdRoleResponse(params).pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * Upload an attachment file for a corpus on the VisualCRM server
   * @param corpId corpus id
   */
    postV1CorpusCorpIdAttachmentResponse(corpId: number): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'POST',
            `${this.rootUrl}/v1/corpus/${corpId}/attachment`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Upload an attachment file for a corpus on the VisualCRM server
   * @param corpId corpus id
   */
    postV1CorpusCorpIdAttachment(corpId: number): __Observable<null> {
        return this.postV1CorpusCorpIdAttachmentResponse(corpId).pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * Delete all attachement for the corpus
   * @param corpId corpus id
   */
    deleteV1CorpusCorpIdAttachmentResponse(corpId: number): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'DELETE',
            `${this.rootUrl}/v1/corpus/${corpId}/attachment`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Delete all attachement for the corpus
   * @param corpId corpus id
   */
    deleteV1CorpusCorpIdAttachment(corpId: number): __Observable<null> {
        return this.deleteV1CorpusCorpIdAttachmentResponse(corpId).pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * Download an attachment file for a corpus from the VisualCRM server
   * @param corpId corpus id
   */
    getV1CorpusCorpIdAttachmentDownloadResponse(corpId: number): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/corpus/${corpId}/attachment/download`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Download an attachment file for a corpus from the VisualCRM server
   * @param corpId corpus id
   */
    getV1CorpusCorpIdAttachmentDownload(corpId: number): __Observable<null> {
        return this.getV1CorpusCorpIdAttachmentDownloadResponse(corpId).pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
     * Get corpus columns used for tone
     * @param corpId corpus id
     * @return OK
     */
    getV1CorpusCorpIdColumns(type: DashboardType, corpId: number, verbatimDbName: string): __Observable<Columnsused> {
        return this.getV1CorpusCorpIdColumnsResponse(type, corpId, verbatimDbName).pipe(
            __map(({ body }) => body as Columnsused),
        );
    }

    /**
     * Get corpus columns used for analyse
     * @param
     *        corpId: corpus id
     *        verbatimDbName: verbatim dbname
     * @return OK
     * */
    getV1CorpusCorpIdColumnsResponse(type: DashboardType, corpId: number, verbatimDbName: string): __Observable<__StrictHttpResponse<Columnsused>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/corpus/${corpId}/verbatim/${verbatimDbName}/columns/${type}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Columnsused>),
        );
    }

    /**
     * Get pagination for columns used for analyse
    * @param params The `PostV1CorpusCorpIdColumnsPaginationParams` containing the following parameters:
    *
    *        corpId: corpus id
    *        verbatimDbName: verbatim_dbname
    *
    * - `body`:
    *
    * @return OK
    */
    postV1CorpusCorpIdColumnsAnalysePaginationResponse(params: PostV1CorpusCorpIdColumnsPaginationParams): __Observable<__StrictHttpResponse<Columnsused>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;
        __body = params.body;
        const req = new HttpRequest<any>(
            'POST',
            `${this.rootUrl}/v1/corpus/${params.corpId}/verbatim/${params.verbatimDbName}/columns/analyse/pagination`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Columnsused>),
        );
    }

    /**
  * Get pagination for columns used for analyse
* @param params The `PostV1CorpusCorpIdColumnsPaginationParams` containing the following parameters:
*
* - `corpId`: corpus id
*
* - `body`:
*
* @return OK
*/
    postV1CorpusCorpIdColumnsAnalysePagination(params: PostV1CorpusCorpIdColumnsPaginationParams): __Observable<Columnsused> {
        return this.postV1CorpusCorpIdColumnsAnalysePaginationResponse(params).pipe(
            __map(({ body }) => body as Columnsused),
        );
    }

    /**
   * Get pagination for columns used for tone
   * @param params The `PostV1CorpusCorpIdColumnsPaginationParams` containing the following parameters:
   *
   * - `corpId`: corpus id
   * - 'verbatimDbName': verbatim dbname
   *
   * - `body`:
   *
   * @return OK
   */
    postV1CorpusCorpIdColumnsTonePaginationResponse(params: PostV1CorpusCorpIdColumnsPaginationParams): __Observable<__StrictHttpResponse<Columnsused>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;
        __body = params.body;
        const req = new HttpRequest<any>(
            'POST',
            `${this.rootUrl}/v1/corpus/${params.corpId}/verbatim/${params.verbatimDbName}/columns/tone/pagination`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Columnsused>),
        );
    }

    /**
   * Get pagination for columns used for tone
   * @param params The `PostV1CorpusCorpIdColumnsPaginationParams` containing the following parameters:
   *
   * - `corpId`: corpus id
   * - 'verbatimDbName': verbatim dbname
   *
   * - `body`:
   *
   * @return OK
   */
    postV1CorpusCorpIdColumnsTonePagination(params: PostV1CorpusCorpIdColumnsPaginationParams): __Observable<Columnsused> {
        return this.postV1CorpusCorpIdColumnsTonePaginationResponse(params).pipe(
            __map(({ body }) => body as Columnsused),
        );
    }

    /**
   * Ask for a new analysis of files which are in the state ANALYSE_NEEDED
   * @param corpId corp id
   */
    getV1CorpusCorpIdAnalyseResponse(corpId: number): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/corpus/${corpId}/analyse`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Ask for a new analysis of files which are in the state ANALYSE_NEEDED
   * @param corpId corp id
   */
    getV1CorpusCorpIdAnalyse(corpId: number): __Observable<null> {
        return this.getV1CorpusCorpIdAnalyseResponse(corpId).pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * Ask for a clustering of verbatims data
   * @param corpId corp id
   */
    getV1CorpusCorpIdClusteringStartResponse(corpId: number): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/corpus/${corpId}/clustering/start`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Ask for a clustering of verbatims data
   * @param corpId corp id
   */
    getV1CorpusCorpIdClusteringStart(corpId: number): __Observable<null> {
        return this.getV1CorpusCorpIdClusteringStartResponse(corpId).pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * Ask for a deletion of a clustering of verbatims data
   * @param corpId corp id
   */
    deleteV1CorpusCorpIdClusteringResponse(corpId: number): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'DELETE',
            `${this.rootUrl}/v1/corpus/${corpId}/clustering`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Ask for a deletion of a clustering of verbatims data
   * @param corpId corp id
   */
    deleteV1CorpusCorpIdClustering(corpId: number): __Observable<null> {
        return this.deleteV1CorpusCorpIdClusteringResponse(corpId).pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * Ask for data clustering of a project
   * @param corpId corp id
   */
    getV1CorpusCorpIdClusteringDataResponse(corpId: number): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/corpus/${corpId}/clustering/data`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Ask for data clustering of a project
   * @param corpId corp id
   */
    getV1CorpusCorpIdClusteringData(corpId: number): __Observable<null> {
        return this.getV1CorpusCorpIdClusteringDataResponse(corpId).pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * NEW FOR MULTIVERBATIM
   * Get corpus class and its data count
   * @param
   *        corpId: corpus id
   *        verbatimDbName: verbatim dbname
   * @return OK
   */
    getV1CorpusCorpIdClassDataResponse(corpId: number, verbatimDbName: string): __Observable<__StrictHttpResponse<ClassCount>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/corpus/${corpId}/verbatim/${verbatimDbName}/class/data`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<ClassCount>),
        );
    }

    /**
   * Get corpus class and its data count
   * @param corpId corpus id
   * @return OK
   */
    getV1CorpusCorpIdClassData(corpId: number, verbatimDbName: string): __Observable<ClassCount> {
        return this.getV1CorpusCorpIdClassDataResponse(corpId, verbatimDbName).pipe(
            __map(({ body }) => body as ClassCount),
        );
    }

    /**
   * NEW FOR CLUSTERS
   * Get corpus clusters and its data count
   * @param
   *        corpId: corpus id
   *        verbatimDbName: verbatim dbname
   * @return OK
   */
    getV1CorpusCorpIdClustersDataResponse(corpId: number, verbatimDbName: string): __Observable<__StrictHttpResponse<ClusterCount>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/corpus/${corpId}/verbatim/${verbatimDbName}/clusters/data`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<ClusterCount>),
        );
    }

    /**
   * Get corpus class and its data count
   * @param corpId corpus id
   * @return OK
   */
    getV1CorpusCorpIdClustersData(corpId: number, verbatimDbName: string): __Observable<ClusterCount> {
        return this.getV1CorpusCorpIdClustersDataResponse(corpId, verbatimDbName).pipe(
            __map(({ body }) => body as ClusterCount),
        );
    }

    /** NEW for multiverbatim
   * Get pagination for columns used for search
   * @param params The `PostV1CorpusCorpIdColumnsPaginationParams` containing the following parameters:
   *
   * - `corpId`: corpus id
   *
   * - `body`:
   *
   * @return OK
   */
    postV1CorpusCorpIdColumnsSearchPaginationResponse(params: PostV1CorpusCorpIdColumnsSearchParams): __Observable<__StrictHttpResponse<SearchResults>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;
        __body = params.body;
        const req = new HttpRequest<any>(
            'POST',
            `${this.rootUrl}/v1/corpus/${params.corpId}/verbatim/${params.verbatimDbName}/columns/search/pagination`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<SearchResults>),
        );
    }

    /**
   * Get pagination for columns used for search
   * @param params The `PostV1CorpusCorpIdColumnsSearchParams` containing the following parameters:
   *
   * - `corpId`: corpus id
   *
   * - `body`:
   *
   * @return OK
   */
    postV1CorpusCorpIdColumnsSearchPagination(params: PostV1CorpusCorpIdColumnsSearchParams): __Observable<SearchResults> {
        return this.postV1CorpusCorpIdColumnsSearchPaginationResponse(params).pipe(
            __map(({ body }) => body as SearchResults),
        );
    }

    /**
   * Get pagination for columns used for search
   * @param params The `PostV1CorpusCorpIdColumnsSearchParams` containing the following parameters:
   *
   * - `corpId`: corpus id
   *
   * - `body`:
   *
   * @return OK
   */
    postV1CorpusCorpIdColumnsSearchResponse(params: PostV1CorpusCorpIdColumnsSearchParams): __Observable<__StrictHttpResponse<SearchResults>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;
        __body = params.body;
        const req = new HttpRequest<any>(
            'POST',
            `${this.rootUrl}/v1/corpus/${params.corpId}/verbatim/${params.verbatimDbName}/columns/search`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<SearchResults>),
        );
    }

    /**
   * Get pagination for columns used for search
   * @param params The `PostV1CorpusCorpIdColumnsSearchParams` containing the following parameters:
   *
   * - `corpId`: corpus id
   *
   * - `body`:
   *
   * @return OK
   */
    postV1CorpusCorpIdColumnsSearch(params: PostV1CorpusCorpIdColumnsSearchParams): __Observable<SearchResults> {
        return this.postV1CorpusCorpIdColumnsSearchResponse(params).pipe(
            __map(({ body }) => body as SearchResults),
        );
    }

    /**
   * Get field format in ElasticSearch for the specified field of a corpus
   * @param params The `GetV1CorpusCorpIdFormatParams` containing the following parameters:
   *
   * - `corpId`: corpus id
   *
   * - `body`:
   *
   * @return OK
   */
    getV1CorpusCorpIdFormatSearchResponse(params: GetV1CorpusCorpIdFormatParams): __Observable<__StrictHttpResponse<SearchResults>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();

        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/corpus/${params.corpId}/format/${encodeURIComponent(params.fieldname)}`,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<SearchResults>),
        );
    }

    /**
   * Get field format in ElastichSearch for a specified corpus field
   * @param params the `GetV1CorpusCorpIdFormatParams`
   * containing the following parameters :
   *  - `corpId`: corpus id  (integer)
   */
    getV1CorpusCorpIdFormat(params: GetV1CorpusCorpIdFormatParams): __Observable<SearchResults> {
        return this.getV1CorpusCorpIdFormatSearchResponse(params).pipe(
            __map(({ body }) => body as SearchResults),
        );
    }

    /**
     * Requête pour obtenir la liste des termes similaires suivant les paramètres données
     * */
    postV1CorpusCorpIdVerbatimSimilarity(params: PostV1CorpusCorpIdVerbatimSimilarityParams): __Observable<SimilarityResponse> {
        return this.http.post<SimilarityResponse>(`${this.rootUrl}/v1/corpus/${params.corpId}/verbatim/${params.verbatimDbName}/similarity`, params.body);
    }

    /**
     * Requête pour télécharger les termes similaires de la recherche
     */
    getV1CorpusCorpIdVerbatimSimilarityDownload(params: PostV1CorpusCorpIdVerbatimSimilarityParams): void {
        this.http.post(
            `${this.rootUrl}/v1/corpus/${params.corpId}/verbatim/${params.verbatimDbName}/similarity/download`,
            params.body,
            { responseType: 'blob', observe: 'response' },
        ).subscribe((resp) => {
            const filename = resp.headers.get('content-disposition').split('=')[1];
            download(window.URL.createObjectURL(resp.body), filename);
        });
    }

    /**
     * Requête pour obtenir les informations de conversation avec les bookmarks associés
     */
    getV1ConversationVerbatim(corpId: number, verbatimDbName: string, conversationId: string): __Observable<VerbatimConversation> {
        return this.http.get<VerbatimConversation>(`${this.rootUrl}/v1/corpus/${corpId}/verbatim/${verbatimDbName}/conversation/${conversationId}/verbatim`);
    }

    /**
     * Requête pour réaliser la configuration liée à la conversation
     */
    postV1ConversationConfiguration(corpId: number, verbatimDbName: string, conversationId: string, body: VerbatimConversationConfig) {
        return this.http.post(`${this.rootUrl}/v1/corpus/${corpId}/verbatim/${verbatimDbName}/conversation/${conversationId}/configuration`, body);
    }

    /**
     * Requête pour obtenir les interlocuteurs d'une conversation
     */
    getV1ConversationSpeakers(corpId: number, verbatimDbName: string): __Observable<VerbatimConversationSpeakers> {
        return this.http.get<VerbatimConversationSpeakers>(`${this.rootUrl}/v1/corpus/${corpId}/verbatim/${verbatimDbName}/conversation/speakers`);
    }

    /**
     * Requête pour créer un ou plusieurs tags sur des conversations
     */
    postV1ConversationTags(corpId: number, body: ConversationAddTags) {
        return this.http.post(`${this.rootUrl}/v1/corpus/${corpId}/tags`, body);
    }

    /**
     * Requête récupérer la liste des tags pour la colonne de verbatim d'un corpus
     */
    getV1ConversationTags(corpId: number, verbatimDbName: string): __Observable<VerbatimTags> {
        return this.http.get<VerbatimTags>(`${this.rootUrl}/v1/corpus/${corpId}/verbatim/${verbatimDbName}/tags`);
    }

    /**
     * Requête pour renommer un tag
     */
    postV1ConversationTagsRename(corpId: number, body: ConversationRenameTag[]) {
        return this.http.post(`${this.rootUrl}/v1/corpus/${corpId}/tags/rename`, body);
    }

    /**
     * Requête pour supprimer un tag sur une conversation
     */
    postV1ConversationTagsDelete(corpId: number, body: ConversationDeleteTag) {
        return this.http.post(`${this.rootUrl}/v1/corpus/${corpId}/tags/delete`, body);
    }

    // Créer ou met à jour une conversation avec un LLM
    createFolder(type: FolderType, name: string): __Observable<Folder> {
        return this.http.post<Folder>(`${this.rootUrl}/v1/folder/`, { name, type });
    }

    getFolderContent(type: FolderType, corpId: number): __Observable<Array<Folder>> {
        return this.http.post<Array<Folder>>(`${this.rootUrl}/v1/folder/${type}/childs`, { corp_id: corpId });
    }

    updateFolderContent(foldId: number, childIds: number[]): __Observable<any> {
        return this.http.patch(`${this.rootUrl}/v1/folder/${foldId}/childs`, { child_ids: childIds });
    }

    renameFolder(foldId: number, newName: string): __Observable<any> {
        return this.http.patch(`${this.rootUrl}/v1/folder/${foldId}`, { name: newName });
    }

    deleteFolder(foldId: number): __Observable<any> {
        return this.http.delete(`${this.rootUrl}/v1/folder/${foldId}`);
    }

    deleteAiConfiguration(type: FolderType, configId) : __Observable<any> {
        return this.http.delete(`${this.rootUrl}/v1/${type}/${configId}`);
    }

    renameAiConfiguration(type: FolderType, configId, name): __Observable<any> {
        return this.http.patch(`${this.rootUrl}/v1/${type}/${configId}`, { name });
    }

    setFavorite(type:FolderType, configId: number) {
        return this.http.post<IaConfigResponse>(`${this.rootUrl}/v1/${type}/${configId}/favorite`, null);
    }

    unsetFavorite(type: FolderType, configId: number) {
        return this.http.delete<void>(`${this.rootUrl}/v1/${type}/${configId}/favorite`);
    }

    shareAiConfiguration(type: FolderType, corpusId: number, configId, sharedAll: boolean, sharedSelect: boolean, usernames: string[]): __Observable<IaConfigResponse> {
        return this.http.patch<IaConfigResponse>(`${this.rootUrl}/v1/${type}/${configId}/share`, {
            corp_id: corpusId,
            shared_all: sharedAll,
            shared_select: sharedSelect,
            usernames,
        });
    }

    unshareAiConfiguration(type: FolderType, corpusId: number, configId, unshared: 'all' | 'select', userId?:number): __Observable<IaConfigResponse> {
        return this.http.patch<IaConfigResponse>(`${this.rootUrl}/v1/${type}/${configId}/unshare`, {
            corp_id: corpusId,
            unshared,
            user_id: userId,
        });
    }

    createAiConfiguration(type: FolderType, name: string, definition: string): __Observable<IaConfigResponse> {
        if (type === FolderType.Thematic) {
            return this.http.post<IaConfigResponse>(`${this.rootUrl}/v1/${type}/`, { name, definition: [definition] });
        }
        return this.http.post<IaConfigResponse>(`${this.rootUrl}/v1/${type}/`, { name, configuration: definition });
    }

    shareAll(corpusId: number) {
        return this.http.post<void>(`${this.rootUrl}/v1/corpus/${corpusId}/share`, {});
    }

    unshareAll(corpusId: number) {
        return this.http.delete<void>(`${this.rootUrl}/v1/corpus/${corpusId}/share`, {});
    }
}

module CorpusService {
}

export default CorpusService;
