// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.supra-navbar {
  transition: top 0.3s ease, height 0.3s ease;
}

.d-none {
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.navbar-nav .nav-item {
  padding-left: 40px;
}
.navbar-nav .nav-item:last-child {
  padding-right: 5px;
}

.navbar {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 34px;
  padding-right: 34px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
