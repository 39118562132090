/* eslint-disable import/no-import-module-exports */
import {
    AfterViewInit,
    Component, OnInit, TemplateRef, ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Store } from '@ngxs/store';
import CorpusService from 'app/utils/services/corpus.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { firstValueFrom, Observable } from 'rxjs';
import fadeInAnimation from 'app/utils/_animations/fade-in.animation';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import ModalComponent from 'app/shared/components/modal/modal.component';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import { GetCorpus } from '../../../../api/models';
import { CorpusService as CorpusApi, UserService } from '../../../../api/services';
import CorpusState from '../../../../stores/state/corpus/corpus.state';

/**
 * Corpus users settings
 *
 * @export
 * @class CorpusSettingsUsersComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'app-corpus-settings-users',
    templateUrl: './corpus-settings-users.component.html',
    styleUrls: ['./corpus-settings-users.component.scss'],
    animations: [fadeInAnimation],
    moduleId: module.id.toString(),
})
export default class CorpusSettingsUsersComponent implements OnInit, AfterViewInit {
    corpusState$: Observable<GetCorpus>;

    columns = [];

    users: any = [];

    user: string = '';

    customerName: string = '';

    shareAll: boolean = false;

    messageOnAddUser: any = {};

    isCorpusAdmin : boolean = false;

    private id;

    modalRef: NgbModalRef;

    @ViewChild('delRowTpl')
        delRowTpl: TemplateRef<any>;

    @ViewChild('adminRowTpl')
        adminRowTpl: TemplateRef<any>;

    @ViewChild('guestRowTpl')
        guestRowTpl: TemplateRef<any>;

    @ViewChild('readerRowTpl')
        readerRowTpl: TemplateRef<any>;

    @ViewChild('userTpl')
        userTpl: TemplateRef<any>;

    @ViewChild('gdprInfoModal')
        gdprModal: TemplateRef<any>;

    @ViewChild('adminColTpl')
        adminColTpl: TemplateRef<any>;

    @ViewChild('guestColTpl')
        guestColTpl: TemplateRef<any>;

    constructor(
        private store: Store,
        private userService: UserService,
        private corpusApi: CorpusApi,
        private corpusService: CorpusService,
        private permissionsService: NgxPermissionsService,
        private modalService: NgbModal,
    ) {
        this.isCorpusAdmin = this.permissionsService.getPermission('corpusAdmin') !== undefined;
        this.corpusState$ = this.store.select(CorpusState);
        this.corpusState$.subscribe((corpus) => {
            this.users = this.defaultSort(
                corpus.users.map((u) => {
                    const user: any = { ...u };
                    if (Number.parseInt(user.role_id, 10) === 1) {
                        user.admin = true;
                    } else if (Number.parseInt(user.role_id, 10) === 2) {
                        user.admin = false;
                    } else {
                        user.admin = false;
                    }
                    return user;
                }),
            );
            this.id = corpus.corp_id;

            this.customerName = corpus.customer_name;
            this.shareAll = corpus.corp_is_public === 1;
        });
    }

    ngOnInit(): void {
        this.corpusService.currentCorpus.subscribe((corpus) => {
            if (corpus !== null) {
                this.setColumns();
            }
        });
    }

    // eslint-disable-next-line class-methods-use-this
    isNotEmpty(obj: object): boolean {
        return Object.keys(obj).length > 0;
    }

    async onShareAllChange(event: Event) {
        this.shareAll = (event.target as HTMLInputElement).checked;

        if (this.shareAll) {
            await firstValueFrom(this.corpusApi.shareAll(this.id));
        } else {
            await firstValueFrom(this.corpusApi.unshareAll(this.id));
        }

        this.corpusService.loadCorpus(this.id);
    }

    get adminCount() {
        return this.users.filter((user) => user.admin).length;
    }

    setColumns() {
        this.columns = [
            {
                prop: 'surname',
                title: 'translations.corpus.settings.users.username',
                cellTemplate: this.userTpl,
                maxWidth: 300,
                style: {
                    width: '300px',
                },
            },
            {
                title: 'translations.corpus.settings.users.admin',
                cellTemplate: this.adminRowTpl,
                headerTemplate: this.adminColTpl,
                maxWidth: 140,
                style: {
                    'text-align': 'center',
                    width: '140px',
                },
            },
            {
                title: 'translations.corpus.settings.users.reader',
                cellTemplate: this.guestRowTpl,
                headerTemplate: this.guestColTpl,
                maxWidth: 140,
                style: {
                    'text-align': 'center',
                    width: '140px',
                },
            },
            {
                title: 'translations.corpus.settings.users.action',
                headerClass: 'text-end',
                cellClass: 'text-end',
                maxWidth: 86,
                cellTemplate: this.delRowTpl,
                style: { 'text-align': 'center', width: '60px' },
            },
        ];
    }

    ngAfterViewInit(): void {
        this.setColumns();
    }

    isCorpusOwner(user): boolean {
        return this.store.snapshot().corpus.corp_create_fk_username === user.username;
    }

    isConnectedUser(user): boolean {
        return this.store.snapshot().user.username === user.username;
    }

    isDeleteAllowed(user): boolean {
        return !this.isConnectedUser(user) && !this.isCorpusOwner(user) && !user.customer_linked;
    }

    // eslint-disable-next-line class-methods-use-this
    defaultSort(datas) {
        if (!datas) {
            return [];
        }
        return JSON.parse(
            JSON.stringify(
                datas.sort((rowA, rowB) => {
                    const a = `${rowA.surname} ${rowA.givenname}`;
                    const b = `${rowB.surname} ${rowB.givenname}`;

                    if (a.toString().toUpperCase() < b.toString().toUpperCase()) {
                        return -1;
                    }
                    if (a.toString().toUpperCase() > b.toString().toUpperCase()) {
                        return 1;
                    }
                    return 0;
                }),
            ),
        );
    }

    addUser(e) {
        e.preventDefault();

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(this.user)) {
            this.messageOnAddUser = { type: 'danger', msg: 'translations.utils.form.emailNotValid' };
            return;
        }

        if (this.users.find((user) => user.mail === this.user)) {
            this.messageOnAddUser = { type: 'danger', msg: 'translations.corpus.settings.users.toast.exists', user: this.user };
            return;
        }

        this.userService.getV1UserEmailExist(this.user).subscribe({
            next: (cuid) => {
                this.corpusApi
                    .postV1CorpusCorpIdRole({
                        username: { username: cuid.username, role_id: 2 },
                        corpId: this.id,
                    })
                    .subscribe({
                        next: () => {
                            this.messageOnAddUser = { type: 'success', msg: 'translations.corpus.settings.users.toast.added', user: this.user };
                            this.corpusService.loadCorpus(this.id);
                            this.user = '';

                            setTimeout(() => {
                                this.messageOnAddUser = {};
                            }, 2000);
                        },
                        error: () => {
                            this.messageOnAddUser = { type: 'danger', msg: 'translations.corpus.settings.users.toast.notadded', user: this.user };
                        },
                    });
            },
            error: () => {
                this.messageOnAddUser = { type: 'danger', msg: 'translations.corpus.settings.users.toast.notfound', user: this.user };
            },
        });
    }

    deleteUser(name) {
        const modal = this.modalService.open(ModalComponent, {});
        modal.componentInstance.titleToTranslate = 'translations.corpus.settings.users.deleteUserModal.title';
        modal.componentInstance.contentToTranslate = 'translations.corpus.settings.users.deleteUserModal.message';
        modal.componentInstance.btnCloseToTranslate = 'translations.utils.cancel';
        modal.componentInstance.btnValidToTranslate = 'translations.utils.confirm';
        modal.componentInstance.btnValidVariant = 'primary';
        modal.componentInstance.status.subscribe(async (s: boolean) => {
            if (s) {
                gtmClick({
                    track_category: 'gestion',
                    track_name: 'partage suppression utilisateur',
                    track_cible: 'valider',
                });
                this.corpusApi
                    .deleteV1CorpusCorpIdRole({
                        username: { username: name },
                        corpId: this.id,
                    })
                    .subscribe(() => {
                        this.corpusService.loadCorpus(this.id);
                    });
            } else {
                gtmClick({
                    track_category: 'gestion',
                    track_name: 'partage suppression utilisateur',
                    track_cible: 'annuler',
                });
            }
        });
    }

    onChange(item, user) {
        const users = this.users.map((u) => {
            if (u.username === user) {
                u[item] = !u[item];
                if (u.admin) {
                    u.role_id = 1;
                } else {
                    u.role_id = 2;
                }
                this.corpusApi
                    .putV1CorpusCorpIdRole({
                        username: { username: user, role_id: u.role_id },
                        corpId: this.id,
                    })
                    .subscribe(() => {
                        this.corpusService.loadCorpus(this.id);
                    });
            }
            return u;
        });
        this.users = [...users];
    }

    openGdprModal(e) {
        e.preventDefault();
        this.modalRef = this.modalService.open(this.gdprModal, {
            backdrop: 'static',
        });
    }

    // eslint-disable-next-line class-methods-use-this
    getLowercaseFirstLetter(text): string {
        return text.charAt(0).toLowerCase() + text.slice(1);
    }

    getDeleteTitle(user) {
        if (!this.isCorpusAdmin) {
            return 'delete_no_admin';
        }
        if (this.isCorpusOwner(user)) {
            return 'delete_creator';
        }
        if (this.adminCount === 1 && user.admin) {
            return 'delete_last_admin';
        }
        if (this.isConnectedUser(user)) {
            return 'delete_itself';
        }
        if (user.customer_linked) {
            return 'delete_customer_linked';
        }
        return 'delete';
    }
}
