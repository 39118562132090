import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import SharedModule from 'app/shared/shared.module';
import AuthGuardService from 'app/utils/services/authguard.service';
import { FormsModule } from '@angular/forms';
import BannerComponent from './banner/banner.component';
import HomeRoutingModule from './home-routing.module';
import HomeComponent from './home.component';
import MosaicItemComponent from './mosaic-item/mosaic-item.component';
import MosaicComponent from './mosaic/mosaic.component';
import ProjectsSectionComponent from './projects-section/projects-section.component';
import ProjectListComponent from './project-list/project-list.component';
import ShareActionComponent from './share-action/share-action.component';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        HomeRoutingModule,
        TranslateModule.forChild(),
        FormsModule,
    ],
    declarations: [HomeComponent, BannerComponent, ProjectsSectionComponent, MosaicComponent, MosaicItemComponent, ProjectListComponent, ShareActionComponent],
    providers: [AuthGuardService],
    exports: [HomeComponent],
})
export default class HomeModule {}
