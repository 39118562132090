import { Component, Input } from '@angular/core';

@Component({
    selector: 'mosaic',
    templateUrl: './mosaic.component.html',
    styleUrl: './mosaic.component.scss',
})
export default class MosaicComponent {
    allRows = [];

    displayedRows = [];

    showMore: boolean = false;

    @Input()
    set rows(rows) {
        this.allRows = [...rows];

        if (this.showMore) {
            this.setShowMore();
        } else {
            this.setShowLess();
        }
    }

    setShowMore() {
        this.showMore = true;
        this.displayedRows = this.allRows || [];
    }

    setShowLess() {
        this.showMore = false;
        this.displayedRows = this.allRows?.slice(0, 6) || [];
    }
}
