import {
    Component, Input,
    OnInit,
} from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { Display } from '../home.service';

export enum Sort {
    RECENT = 'recent',
    FAVORITE = 'favorite',
    TITLE_ASC = 'title_asc',
    TITLE_DESC = 'title_desc',
    TOOLS = 'tools',
    DATE_ASC = 'date_asc',
    ROLE_ASC = 'role_asc',
    ROLE_DESC = 'role_desc',
    AUTHOR_ASC = 'author_asc',
    AUTHOR_DESC = 'author_desc',
    LANG_ASC = 'lang_asc',
    LANG_DESC = 'lang_desc',
}

@Component({
    selector: 'projects-section',
    templateUrl: './projects-section.component.html',
    styleUrl: './projects-section.component.scss',
})
export default class ProjectsSectionComponent implements OnInit {
    constructor(private permissionService: NgxPermissionsService) {

    }

    ngOnInit(): void {
        this.sortRows();
    }

    @Input()
        type: string;

    filteredRows;

    sortedRows;

    @Input()
        selectedDisplayMode: Display = Display.GRID;

    sort : Sort = Sort.RECENT;

    // eslint-disable-next-line class-methods-use-this
    get displayModes() {
        return Display;
    }

    @Input()
    set rows(rows) {
        this.filteredRows = rows;
        this.sortRows();
    }

    sortRows() {
        this.sortedRows = this.getSortedRows();
    }

    // eslint-disable-next-line class-methods-use-this
    get sortValues() {
        return Sort;
    }

    get isAdmin() {
        return this.permissionService.getPermission('administrators');
    }

    // eslint-disable-next-line class-methods-use-this
    get sortkeys() {
        return Object.keys(Sort).filter((key) => Number.isNaN(Number(key)));
    }

    // eslint-disable-next-line class-methods-use-this
    getSortTrackCible(sort: Sort) {
        switch (sort) {
            case Sort.RECENT:
                return 'récent';
            case Sort.TITLE_ASC:
                return 'titres a-z';
            case Sort.TITLE_DESC:
                return 'titres z-a';
            case Sort.FAVORITE:
                return 'favoris';
            case Sort.TOOLS:
                return 'outils';
            case Sort.LANG_ASC:
                return 'langues a-z';
            case Sort.LANG_DESC:
                return 'langues z-a';
            case Sort.DATE_ASC:
                return 'dates';
            case Sort.AUTHOR_ASC:
                return 'auteurs a-z';
            case Sort.AUTHOR_DESC:
                return 'auteurs z-a';
            case Sort.ROLE_ASC:
                return 'rôles a-z';
            case Sort.ROLE_DESC:
                return 'rôles z-a';
            default:
                return '';
        }
    }

    getSortedRows() {
        if (!this.filteredRows) {
            return [];
        }
        const sortedRows = [...this.filteredRows];

        switch (this.sort) {
            case Sort.RECENT:
                return sortedRows.sort((a, b) => b.dateLastUpload.localeCompare(a.dateLastUpload));
            case Sort.TITLE_ASC:
                return sortedRows.sort((a, b) => a.corp_name.localeCompare(b.corp_name));
            case Sort.TITLE_DESC:
                return sortedRows.sort((a, b) => b.corp_name.localeCompare(a.corp_name));
            case Sort.FAVORITE:
                // eslint-disable-next-line no-nested-ternary
                return sortedRows.sort((a, b) => (a.is_favori === b.is_favori ? 0 : (a.is_favori ? -1 : 1)));
            case Sort.TOOLS:
                // eslint-disable-next-line no-nested-ternary
                return sortedRows.sort((a, b) => ((a.url_kibana_dashboard_main || a.url_external_dashboard) === (b.url_kibana_dashboard_main || b.url_external_dashboard)
                    ? 0 : ((a.url_kibana_dashboard_main || a.url_external_dashboard) ? -1 : 1)));
            case Sort.LANG_ASC:
                return sortedRows.sort((a, b) => a.corp_lang.localeCompare(b.corp_lang));
            case Sort.LANG_DESC:
                return sortedRows.sort((a, b) => b.corp_lang.localeCompare(a.corp_lang));
            case Sort.DATE_ASC:
                return sortedRows.sort((a, b) => a.dateLastUpload.localeCompare(b.dateLastUpload));
            case Sort.AUTHOR_ASC:
                return sortedRows.sort((a, b) => a.nameOwner.localeCompare(b.nameOwner));
            case Sort.AUTHOR_DESC:
                return sortedRows.sort((a, b) => b.nameOwner.localeCompare(a.nameOwner));
            case Sort.ROLE_ASC:
                return sortedRows.sort((a, b) => a.role_id - b.role_id);
            case Sort.ROLE_DESC:
                return sortedRows.sort((a, b) => b.role_id - a.role_id);
            default:
                return sortedRows;
        }
    }
}
