// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.favorite {
  width: 16px;
  height: 16px;
}

.icon-kibana {
  width: 20px;
  height: 20px;
}

.actions .btn-icon {
  width: 30px;
  height: 30px;
}

.btn-icon {
  pointer-events: auto;
}

.tool-name {
  width: 70px;
}

.header-favorite {
  margin-left: 1px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
