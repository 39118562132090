import {
    Component, Input,
} from '@angular/core';

@Component({
    selector: 'share-action',
    templateUrl: './share-action.component.html',
    styleUrl: './share-action.component.scss',
})
export default class ShareActionComponent {
    @Input()
        row;

    @Input()
        size: number = 24;

    @Input()
        track_category = 'page projets';

    get processInError() {
        return this.row.corp_status?.error;
    }

    get processInProgress() {
        return !this.processInError && (this.row.corp_status?.status_flags?.isAnalyseInProgress || this.row.corp_status?.status_flags?.hasAsyncTreatmentInProgress);
    }

    get titleType() {
        if (this.row.corp_type === 4) {
            return 'conversation';
        }
        return 'project';
    }

    getTooltipDisabled() {
        let title = 'translations.home.share_tooltip';

        if (this.row.role_id !== 1) {
            title += '_guest';
        } else if (this.processInProgress) {
            title += '_in_progress';
        } else if (this.processInError) {
            title += '_error';
        }

        return `${title}_${this.titleType}`;
    }
}
