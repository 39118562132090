import {
    Component, Input,
    OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import CorpusService from 'app/utils/services/corpus.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { BaseComponent } from 'app/base.component';
import HomeService from '../home.service';

@Component({
    selector: 'mosaic-item',
    templateUrl: './mosaic-item.component.html',
    styleUrl: './mosaic-item.component.scss',
})
export default class MosaicItemComponent extends BaseComponent implements OnInit {
    constructor(
        private corpusService: CorpusService,
        private translateService: TranslateService,
        private permissionService: NgxPermissionsService,
        private homeService: HomeService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.lang = (this.translateService.currentLang || this.translateService.defaultLang).toLowerCase();
        this.subs.sink = this.translateService.onLangChange.subscribe(() => {
            this.lang = this.translateService.currentLang;
        });

        this.formattedLineCount = Intl.NumberFormat(this.translateService.currentLang, { notation: 'compact' }).format(this.row.corp_file_max_lines);
        this.processInProgress = !this.processInError && (this.row.corp_status?.status_flags?.isAnalyseInProgress || this.row.corp_status?.status_flags?.hasAsyncTreatmentInProgress);
        this.needReAnalyse = !this.processInError && this.row.corp_status?.status_flags?.hasFileInReanalyse;
        this.processInError = this.row.corp_status?.error;
        this.canDeleteCorpus = this.row.role_id === 1 && !this.row.corp_is_public;
        this.isAdmin = this.permissionService.getPermission('administrators') !== undefined;
    }

    @Input()
        row;

    formattedLineCount : string = '';

    processInProgress : boolean = false;

    processInError : boolean = false;

    needReAnalyse: boolean = false;

    canDeleteCorpus: boolean = false;

    isAdmin: boolean = false;

    lang: string = 'fr';

    fav() {
        this.corpusService.fav(this.row.corp_id);
        this.row.is_favori = true;
    }

    unFav() {
        this.corpusService.unFav(this.row.corp_id);
        this.row.is_favori = false;
    }

    delete() {
        this.homeService.deleteCorpus(this.row, 'page projets mosaique');
    }
}
