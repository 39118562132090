/* eslint-disable import/no-import-module-exports */
import {
    AfterViewInit, Component,
    HostListener,
} from '@angular/core';
import FooterService from 'app/shared/components/footer/footer.service';
import fadeInAnimation from 'app/utils/_animations/fade-in.animation';
import CorpusService from 'app/utils/services/corpus.service';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import HomeService, { Display } from './home.service';


/**
 * Home component
 *
 * @export
 * @class HomeComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    moduleId: module.id.toString(),
    animations: [fadeInAnimation],
})
export default class HomeComponent implements AfterViewInit {
    public rowsVerbatim: Array<any> = [];

    public rowsConversations: Array<any> = [];

    public filtetedRowsVerbatim: Array<any> = [];

    public filtetedRowsConversations: Array<any> = [];

    isLoaded = false;

    activeLink: string = 'scrollspyverbatim';

    scrollSpyElements = {};

    backToTopVisible: boolean = false;

    previousScrollPosition: number = 0;

    search = '';

    constructor(
        private corpusService: CorpusService,
        private footerService: FooterService,
        private homeService: HomeService,
    ) {}

    get selectedDisplayMode() {
        return this.homeService.selectedDisplayMode;
    }

    set selectedDisplayMode(value) {
        this.homeService.selectedDisplayMode = value;
    }

    // en commentaire en cas de changement d'avis, à supprimer dans la prochaine version sinon
    // eslint-disable-next-line class-methods-use-this
    // scrollToSection(id: string): void {
    //     const element = document.getElementById(id);
    //     if (element) {
    //         element.scrollIntoView({ behavior: 'smooth' });
    //     }
    // }
    @HostListener('window:scroll', [])
    onWindowScroll(): void {
    //     const securitymargin = 100;
        const scrollPosition = window.scrollY;
        //     const screenTopPosition = scrollPosition + securitymargin;
        //     const screenBottomPosition = scrollPosition + securitymargin + document.body.clientHeight;

        this.backToTopVisible = scrollPosition > 201;

        //     ['scrollspyconversations', 'scrollspyverbatim'].forEach((link) => {
        //         if (!this.scrollSpyElements[link]) {
        //             this.scrollSpyElements[link] = document.getElementById(link);
        //         }

        //         const section = this.scrollSpyElements[link];
        //         if (section) {
        //             const sectionBottomPosition = section.offsetTop + section.offsetHeight;
        //             const sectionTopPosition = section.offsetTop;

        //             const bodyVisible = sectionBottomPosition > screenBottomPosition - securitymargin && sectionTopPosition < screenTopPosition;
        //             const topVisible = sectionTopPosition > screenTopPosition && sectionTopPosition < screenBottomPosition;

        //             if (bodyVisible || topVisible) {
        //                 this.activeLink = link;
        //             }
        //         }
        //     });

        this.previousScrollPosition = window.scrollY;
    }

    // eslint-disable-next-line class-methods-use-this
    onSearch(value) {
        gtmClick({
            track_category: 'page projets',
            track_name: 'recherche projet',
        });

        this.search = value;
        this.filtetedRowsVerbatim = this.rowsVerbatim.filter((row) => value === '' || row.corp_name.toLowerCase().includes(value.toLowerCase()));
        this.filtetedRowsConversations = this.rowsConversations.filter((row) => value === '' || row.corp_name.toLowerCase().includes(value.toLowerCase()));
    }

    ngAfterViewInit() {
        this.corpusService.corpusList.subscribe((corpus) => {
            if (corpus !== null) {
                this.rowsVerbatim = corpus.corpusVerbatim;
                this.rowsConversations = corpus.corpusConversation;
                this.filtetedRowsVerbatim = this.rowsVerbatim.filter((row) => this.search === '' || row.corp_name.toLowerCase().includes(this.search.toLowerCase()));
                this.filtetedRowsConversations = this.rowsConversations.filter((row) => this.search === '' || row.corp_name.toLowerCase().includes(this.search.toLowerCase()));
                this.isLoaded = true;
            }
        });

        this.corpusService.loadCorpusListFromBackend();
    }

    // eslint-disable-next-line class-methods-use-this
    get displayModes() {
        return Display;
    }
}
